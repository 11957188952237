import React, { Component } from 'react'
import SEO from '../components/seo'
import { Flex, Box } from '@rebass/grid'

import Title from '../components/page-title'
import Copy from '../components/page-copy'
import Row from '../components/page-content-row'
import Image from '../components/page-image-new'
//import PageOverview from '../components/page-overview';
import PageResourceSide from '../components/page-resources-side'

import Image1 from '../images/resources/happy-haus-holland-park-west-13.jpg'
const ogImages = [`https://happyhaus.com.au${Image1}`]

const PostImage = 'resources/happy-haus-holland-park-west-13.jpg'

class Page extends Component {
  render() {
    return (
      <>
        <SEO
          title="8 simple steps to purchasing a new home"
          description="8 simple steps to purchasing a new home"
          images={ogImages}
        />

        <Row>
          <Flex>
            <Box width={8 / 12} px={2}>
              <Image src={PostImage} ratio={9 / 16} margin={true} />
              <Title align="left">
                8 simple steps to purchasing a new home
              </Title>
              <Copy align="left">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Aliquam eleifend sollicitudin ante. Pellentesque pretium
                  turpis eu ex elementum dapibus. Ut dolor arcu, scelerisque nec
                  laoreet quis, aliquet eleifend est. Vestibulum sit amet lectus
                  eget purus tincidunt semper id sed ipsum. Etiam lobortis mi
                  sit amet mi consequat, id blandit lacus viverra. Curabitur ac
                  sagittis turpis. In blandit ipsum nec pretium cursus. In
                  scelerisque metus sed maximus varius. Curabitur lectus risus,
                  bibendum et ante sit amet, porttitor pellentesque dolor. Duis
                  et accumsan ex, eu mollis mauris.
                </p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Aliquam eleifend sollicitudin ante. Pellentesque pretium
                  turpis eu ex elementum dapibus. Ut dolor arcu, scelerisque nec
                  laoreet quis, aliquet eleifend est. Vestibulum sit amet lectus
                  eget purus tincidunt semper id sed ipsum. Etiam lobortis mi
                  sit amet mi consequat, id blandit lacus viverra. Curabitur ac
                  sagittis turpis. In blandit ipsum nec pretium cursus. In
                  scelerisque metus sed maximus varius. Curabitur lectus risus,
                  bibendum et ante sit amet, porttitor pellentesque dolor. Duis
                  et accumsan ex, eu mollis mauris.
                </p>
              </Copy>
            </Box>
            <Box width={4 / 12} px={2} pl={[0, 50]}>
              <PageResourceSide />
            </Box>
          </Flex>
        </Row>
      </>
    )
  }
}

export default Page
